import React from 'react'
import PropTypes from 'prop-types'
import CustomMap from './CustomMap'

const Geolocation = (props) => {
  return (
    <section id="geolocation" className="contact-area ptb-100">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 offset-lg-2 text-center">
            <CustomMap />
          </div>
        </div>
      </div>
    </section>
  )
}

//Props Types
Geolocation.propTypes = {
  SectionbgTitle: PropTypes.string,
  sectionTitle: PropTypes.string,
  sectionDescription: PropTypes.string,
  AddTitle: PropTypes.string,
  Address: PropTypes.string,
  EmailTitle: PropTypes.string,
  Email: PropTypes.string,
  PhoneTitle: PropTypes.string,
  Phone: PropTypes.string,
}

//Default Props
Geolocation.defaultProps = {
  SectionbgTitle: 'Contact',
  sectionTitle: `Géolocalisation de TALI N'NA MINI ZOO`,
  sectionDescription:
    "Voudriez-vous demander quelques informations concernant le Mini Zoo Parc TALI N'NA? Veuillez remplir les champs ci-dessous, nous nous ferons plaisir de vous y répondre dans les brefs délais.",
  AddTitle: 'Adresse',
  Address: 'Evou-Kodégbé, Amou - Togo.',
  EmailTitle: 'Email',
  Email: 'contact@talinnaminizootogo.com',
  PhoneTitle: 'Téléphone',
  Phone: '+228 98-47-87-87',
}

export default Geolocation
