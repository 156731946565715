import React from 'react'
import { Link } from 'gatsby'
import { graphql, useStaticQuery } from 'gatsby'
import get from 'lodash/get'
//import Lightbox from "react-image-lightbox";
import 'react-image-lightbox/style.css'
import Lightbox from 'react-awesome-lightbox'
// You need to import the CSS only once
import 'react-awesome-lightbox/build/style.css'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

const getImages = graphql`
  query birdsQuery {
    allContentfulAsset(
      filter: { metadata: { tags: { elemMatch: { name: { eq: "Zoo: oiseaux" } } } } }
      limit: 6
    ) {
      nodes {
        url
        title
        filename
      }
      totalCount
    }
  }
`

const Birds = () => {
  const data = useStaticQuery(getImages)
  const [isOpen, setIsOpen] = React.useState(false)
  const [photoIndex, setPhotoIndex] = React.useState(0)
  const [src, setSrc] = React.useState('')

  const images = data.allContentfulAsset.nodes
  //alert(JSON.stringify(images))

  const smallImages = []

  const imagesItems = data.allContentfulAsset.nodes.map((image, index) => (
    <div className="col-sm-6 col-lg-4" key={index}>
      <div className="work-details">
        <img src={image.url} alt="sample" />
        <div className="box-content">
          <h3 className="title">{image.title}</h3>
          <ul className="icon">
            <li>
              <Link
                to="#"
                onClick={(e) => {
                  e.preventDefault()
                  setSrc(
                    `https://d1gq3gv99zt38n.cloudfront.net/510x600/${image.filename}`
                  )
                  setPhotoIndex(0)
                  setIsOpen(true)
                }}
              >
                <i className="fa fa-link"></i>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  ))

  return (
    <React.Fragment>
      <section id="animals" className="our-works ptb-10">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2 text-center">
              <div className="section-title">
                <h2>Galerie des Oiseaux</h2>
                <span className="section-title-bg">
                  Le Mini Zoo TALI N'NA en Images
                </span>
              </div>
            </div>
          </div>

          <div id="Portfolio" className="row">
            {imagesItems}
            <div className="col-lg-12 col-md-12 all-post">
              <div className="center-wrap">
                <Link href={'/birds/'} className="btn-a">
                  <div className="button button-detail ">
                    Voir plus <i className="fa fa-arrow-right"></i>
                    <div className="mask"></div>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      {isOpen && (
        <Lightbox
          image={src}
          startIndex={photoIndex}
          onClose={() => setIsOpen(false)}
        />
      )}
    </React.Fragment>
  )
}

export default Birds
