import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import { Link } from 'gatsby'

const ContentPreview = ({ posts, root, showButton }) => {
  if (!posts) return null
  if (!Array.isArray(posts)) return null

  return (
    <div className="row">
      {posts.map((post) => {
        return (
          <div className="col-md-4 col-lg-4" key={post.slug}>
            <div className="blog-item">
              <a href={`/${root}/${post.slug}`} className="blog-img">
                <GatsbyImage alt="" image={post.heroImage.gatsbyImageData} />
              </a>
              <div className="blog-info">
                <div className="date-box">
                  {post.qty}
                  <span className="month">{`individus`}</span>
                </div>
                <div className="title-meta">
                  <h2>
                    <a href={`/${root}/${post.slug}`}>{post.title}</a>
                  </h2>
                </div>
              </div>
              <div className="post-content">
                <p>
                  {post.description?.raw && renderRichText(post.description)}
                </p>
              </div>
            </div>
          </div>
        )
      })}
      {showButton && (
        <div className="col-lg-12 col-md-12 all-post">
          <div className="center-wrap">
            <Link href={`/${root}/`} className="btn-a">
              <div className="button button-detail">
                Voir plus <i className="fa fa-arrow-right"></i>
                <div className="mask"></div>
              </div>
            </Link>
          </div>
        </div>
      )}
    </div>
  )
}

export default ContentPreview
