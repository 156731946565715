import React from 'react'
import PropTypes from 'prop-types'
import { graphql, useStaticQuery, Link } from 'gatsby'

const getTeamImages = graphql`
  {
    image1: file(relativePath: { eq: "mobile-money-partners-1024x300.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const MobileMoneys = (props) => {
  const data = useStaticQuery(getTeamImages)

  return (
    <section id="mobilemoneys" className="ptb-10">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 offset-lg-2 text-center">
            <div className="section-title">
              <h2>{props.sectionTitle}</h2>
              {/* <p>{props.sectionDescription}</p> */}
              <span className="section-title-bg">{props.SectionbgTitle}</span>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 col-lg-12 text-center">
            <a href="#" className="blog-img">
              <img src={data.image1.childImageSharp.fluid.src} alt="blog-one" />
            </a>
          </div>
        </div>
      </div>
    </section>
  )
}

MobileMoneys.propTypes = {
  SectionbgTitle: PropTypes.string,
  sectionTitle: PropTypes.string,
  sectionDescription: PropTypes.string,
  servicesData: PropTypes.array,
}

//Default Props
MobileMoneys.defaultProps = {
  SectionbgTitle: "Services de Transfert d'argent",
  sectionTitle: "Services de Transfert d'argent disponibles à TALI N'NA",
  sectionDescription:
    "Nous mettons à votre disposition tous les services de transfert d'argent en toute sécurité.",
}

export default MobileMoneys
