import React from 'react'
import Layout from '../components/App/Layout'
import Seo from '../components/App/seo'
import Banner from '../components/Index/Banner'
import Services from '../components/Index/Services'
// import WelcomeServices from "../components/Index/WelcomeServices"
import Works from '../components/Index/Works'
import About from '../components/Index/About'
import Team from '../components/Index/Team'
import Blog from '../components/Index/Blog'
import VideoArea from '../components/Index/VideoArea'
import Pricing from '../components/Index/Pricing'
import FunFacts from '../components/Index/FunFacts'
import Testimonials from '../components/Index/Testimonials'
import Faq from '../components/Index/FAQ'
import Partner from '../components/Index/Partner'
import Contact from '../components/Index/Contact'
import Geolocation from '../components/Index/Geolocation'
import Gallery from '../components/Index/Gallery'
import Rooms from '../components/Index/Rooms'
import Park from '../components/Index/Park'
import Animals from '../components/Index/Animals'
import AnimalsPost from '../components/Index/AnimalsPost'
import MobileMoneys from '../components/Index/MobileMoneys'
import Birds from '../components/Index/Birds'
import Reptiles from '../components/Index/Reptiles'
import Gym from '../components/Index/Gym'

const IndexPage = () => (
  <Layout>
    <Seo title="Accueil" />
    <Banner />
    {/* <div className="mt-130">
            <Services />
        </div> */}
    <VideoArea />
    <Rooms />
    <Park />
    <Animals />
    <Birds />
    <Reptiles />
    {/* <About />
        <Team /> 
        <Pricing />
        <FunFacts />
        <Testimonials /> */}
    {/* <Faq />
        <Partner /> */}
    {/* <AnimalsPost /> */}
    <Gym />
    <Contact />
    <MobileMoneys/>
  </Layout>
)

export default IndexPage
